// 公司评价
<template>
  <div v-bind="$attrs" v-on="$listeners" class="companyEvaluation">
    <div class="addBtn">
      <div class="addButton" @click="addEvaluate">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <!-- 内容 -->
    <div v-for="(item, index) of evaluateData" :key="index" class="content">
      <div class="head">
        <span>被评价公司</span>
        <base-select
          class="selectCompany"
          v-model="item.companyId"
          :options="options"
          :selectedField="['dictId', 'dictName']"
          placeholder="可输入可选择企业名称"
        ></base-select>
      </div>
      <div class="desc">
        <el-input type="textarea" rows="4" v-model="item.desc"></el-input>
        <icon-button
        class="iconBtn"
          @click="deleteEvaluate(item, index)"
          content="删除"
          icon="iconfont iconshanchu1"
        ></icon-button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseSelect from '@/components/common/base-select/base-select.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  props: {
    options: Array
  },
  components: { BaseSelect, IconButton },
  data () {
    return {
      evaluateData: [{ companyId: 1, desc: 'aaaaaaa' }]
      // options: [
      //   { id: 1, name: 1233 }
      // ]
    }
  },
  methods: {
    addEvaluate () {
      this.evaluateData.push({})
    },
    deleteEvaluate (data, index) {
      this.evaluateData.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.addBtn {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding-right: 19px;
  padding-top: 10px;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
.head {
  display: flex;
  margin-bottom: 10px;
}
.selectCompany {
  width: 300px !important;
  margin-left: 10px;
}
.content {
  padding-bottom: 20px;
}
.desc{
  display: flex;
  justify-content: space-between;
}
.iconBtn{
  width: 5%;
 display: flex !important;
 align-content: center;
 justify-content: center;
}
</style>
